import React from "react"
import PropTypes from "prop-types"

const Subscription = ({
  name,
  tagline,
  prices,
  features,
  icon,
  onClick,
  type,
  currectSubscription,
}) => {
  console.log("Current Subscription:", currectSubscription)
  console.log("Name:", name)
  console.log("Type:", type)

  return (
    <div className={`col-md-6 col-xl-3`}>
      <div className="plan-box card">
        <div
          className={`p-4 border card-body ${
            currectSubscription?.plan.toLowerCase() === name.toLowerCase() &&
            currectSubscription?.billing_cycle.toLowerCase() ===
              type.toLowerCase()
              ? "border border-2 border-success"
              : "border-primary"
          }`}
        >
          <div className="d-flex">
            <div className="flex-grow-1">
              <h5 className="text-capitalize">{name}</h5>
              <p className="text-muted">{tagline}</p>
            </div>
            <div className="ms-3">
              {icon === "cycling" && (
                <i
                  className={`bx bx-cycling h1 text-primary ${
                    currectSubscription?.plan.toLowerCase() ===
                      name.toLowerCase() &&
                    currectSubscription?.billing_cycle.toLowerCase() ===
                      type.toLowerCase()
                      ? "text-success"
                      : "text-primary"
                  }`}
                ></i>
              )}
              {icon === "run" && (
                <i
                  className={`bx bx-run h1 text-primary ${
                    currectSubscription?.plan.toLowerCase() ===
                      name.toLowerCase() &&
                    currectSubscription?.billing_cycle.toLowerCase() ===
                      type.toLowerCase()
                      ? "text-success"
                      : "text-primary"
                  }`}
                ></i>
              )}
              {icon === "walking" && (
                <i
                  className={`bx bx-walk h1 text-primary ${
                    currectSubscription?.plan.toLowerCase() ===
                      name.toLowerCase() &&
                    currectSubscription?.billing_cycle.toLowerCase() ===
                      type.toLowerCase()
                      ? "text-success"
                      : "text-primary"
                  }`}
                ></i>
              )}
            </div>
          </div>
          <div className="py-4">
            <h2>
              <sup>
                <small>$</small>
              </sup>{" "}
              {prices?.map(
                (item, index) =>
                  type === item.period && (
                    <React.Fragment key={index}>
                      {item.price}{" "}
                      <span className="font-size-13">{item.period}</span>
                    </React.Fragment>
                  )
              )}
            </h2>
          </div>
          <div className="text-center">
            {prices?.map(
              (item, index) =>
                type === item.period && (
                  <a
                    key={index}
                    href={item.payment_link}
                    className={`btn btn-primary btn-sm btn  ${
                      currectSubscription?.plan.toLowerCase() ===
                        name.toLowerCase() &&
                      currectSubscription?.billing_cycle.toLowerCase() ===
                        type.toLowerCase()
                        ? "disabled btn-success"
                        : "btn-primary"
                    }`}
                    onClick={e => {
                      if (
                        currectSubscription?.plan.toLowerCase() ===
                          name.toLowerCase() &&
                        currectSubscription?.billing_cycle.toLowerCase() ===
                          type.toLowerCase()
                      ) {
                        e.preventDefault()
                      }
                    }}
                  >
                    {currectSubscription?.plan.toLowerCase() ===
                      name.toLowerCase() &&
                    currectSubscription?.billing_cycle.toLowerCase() ===
                      type.toLowerCase()
                      ? "Subscribed"
                      : "Sign up Now"}
                  </a>
                )
            )}
          </div>
          <div className="plan-features mt-5">
            {features?.map((feature, index) => (
              <p key={index}>
                <i className="bx bx-checkbox-square text-primary me-2" />
                {feature.name} {feature.value}
              </p>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

Subscription.propTypes = {
  name: PropTypes.string,
  tagline: PropTypes.string,
  type: PropTypes.any,
  prices: PropTypes.arrayOf(
    PropTypes.shape({
      payment_link: PropTypes.string.isRequired,
      period: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
    })
  ),
  features: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    })
  ),
  icon: PropTypes.oneOf(["cycling", "run", "walking"]),
  onClick: PropTypes.func,
  currectSubscription: PropTypes.object,
}

export default Subscription
