import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { getUserInfo, getUserSusbcriptionInfo, signUpUser } from "store/actions"

export const withSignup = Component => {
  const WithSignupComponent = props => {
    const dispatch = useDispatch()

    const handleSignup = () => {
      const signUpUserForm = new FormData()
      const email = JSON.parse(localStorage.getItem("emailUser"))
      signUpUserForm.append("email", email)
      dispatch(signUpUser(signUpUserForm, props.history))
      dispatch(getUserInfo())
    }

    const { subscribe, loading } = useSelector(state => state.userSubscription)


    console.log("subscribe", subscribe?.subscribed)

    useEffect(() => {
      dispatch(getUserSusbcriptionInfo())
    }, [dispatch])

    useEffect(() => {
      if (
        !loading &&
        !subscribe?.subscribed &&
        subscribe?.subscribed !== undefined &&
        !subscribe?.is_trial
      ) {
        // check if subscription data has loaded
        handleSignup()
      }
    }, [loading, subscribe?.subscribed, dispatch, props.history])

    // Render the original component with all props
    return <Component {...props} />
  }

  WithSignupComponent.propTypes = {
    history: PropTypes.object.isRequired,
  }

  return WithSignupComponent
}
