import { all, call, put, take, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes"
import { apiError, loginSuccess, logoutUserSuccess } from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
/* import {
  postFakeLogin,
  postJwtLogin,
  postSocialLogin,
} from "../../../helpers/backend_helper" */
import { getUserInfo } from "store/user/actions"
import {
  GET_USER_INFO_ERROR,
  GET_USER_INFO_SUCCESS,
} from "store/user/actionTypes"
import { getUserSusbcriptionInfo } from "store/actions"
import { GET_USER_SUBSCRIPTION_INFO_ERROR, GET_USER_SUBSCRIPTION_INFO_SUCCESS } from "store/user-subscription/actionTypes"

const fireBaseBackend = getFirebaseBackend()

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(
      fireBaseBackend.loginUser,
      user.email,
      user.password
    )
    yield put(loginSuccess(response))

   yield put(getUserInfo())
   yield put(getUserSusbcriptionInfo());

   const [userInfoAction, subscriptionInfoAction] = yield all([
     take([GET_USER_INFO_SUCCESS, GET_USER_INFO_ERROR]),
     take([GET_USER_SUBSCRIPTION_INFO_SUCCESS, GET_USER_SUBSCRIPTION_INFO_ERROR]),
   ]);

   if (
     userInfoAction.type === GET_USER_INFO_SUCCESS &&
     subscriptionInfoAction.type === GET_USER_SUBSCRIPTION_INFO_SUCCESS
   ) {
     const subscriptionInfo = subscriptionInfoAction.payload;

     if (!subscriptionInfo?.subscribed && !subscriptionInfo?.is_trial) {
       history.push("/subscription-plan");
     } else {
       history.push("/");
     }
   } else {
     console.error("Failed to fetch user info or subscription plan.");
   }
 } catch (error) {
   yield put(apiError(error));
 }
}


function* logoutUser({ payload: { history } }) {
  try {
    const response = yield call(fireBaseBackend.logout)
    yield put(logoutUserSuccess(response))
    history.push("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* socialLogin({ payload: { data, history, type } }) {
  try {
    const fireBaseBackend = getFirebaseBackend()
    const response = yield call(fireBaseBackend.socialLoginUser, data, type)

    yield put(loginSuccess(response))

    history.push("/")
    //history.push("/home")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeLatest(SOCIAL_LOGIN, socialLogin)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
