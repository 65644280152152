import React, { useState, useEffect, useRef, useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import MetaTags from "react-meta-tags"
import { Container } from "reactstrap"
import PropTypes from "prop-types"
import { getLoggedInUser } from "../../helpers/backend_helper"
import {
  createCheckoutSession,
  getSubscriptionPlan,
  getUserInfo,
  signUpUser,
} from "store/actions"
import mixpanel from "mixpanel-browser"
import Subscription from "components/PrincingPlans"
import { withRouter } from "react-router-dom"

const UserSubscription = props => {
  const items = [
    { title: "Settings", link: "/settings" },
    { title: "Subscription", link: "/settings/subscription" },
  ]
  useEffect(() => {
    const loggedInUser = getLoggedInUser()
    if (loggedInUser !== null) {
      mixpanel.track("subscription", {
        event_properties: {
          user_id: loggedInUser.uid,
        },
      })
    } else {
      mixpanel.track("subscription")
    }
  }, [])

  const dispatch = useDispatch()
  const { subscriptionPlan } = useSelector(state => state.subscription)
  const { user } = useSelector(state => state.user)

  const userRef = useRef(true)

  useEffect(() => {
    dispatch(getSubscriptionPlan())
    dispatch(getUserInfo())
  }, [])

  const [planSubscribe, setPlanSubscribe] = useState("monthly")
  const handleChangePlan = e => {
    const checked = e.target.checked

    if (checked) {
      setPlanSubscribe("yearly")
    } else {
      setPlanSubscribe("monthly")
    }
  }

  const userSubscribe = () => {
    if (!user.subscription) {
      return true
    } else {
      return false
    }
  }

  const handlePaymentEssential = useCallback(() => {
    console.log("testing")
    const checkoutForm = new FormData()
    checkoutForm.append("plan", "essential")
    checkoutForm.append("billing_frequency", "monthly")
    checkoutForm.append(
      "success_redirect",
      "https://content.quillnow.com/payment/success"
    )
    checkoutForm.append(
      "cancel_redirect",
      "https://content.quillnow.com/payment/success"
    )
    checkoutForm.append("is_trial", true)
    dispatch(createCheckoutSession(checkoutForm))
  }, [])

  const handlePaymentStandard = useCallback(e => {
    e.preventDefault()
    const checkoutForm = new FormData()
    checkoutForm.append("plan", "standard")
    checkoutForm.append("billing_frequency", "monthly")
    checkoutForm.append(
      "success_redirect",
      "https://content.quillnow.com/payment/success"
    )
    checkoutForm.append(
      "cancel_redirect",
      "https://content.quillnow.com/payment/success"
    )
    checkoutForm.append("is_trial", true)
    dispatch(createCheckoutSession(checkoutForm))
  }, [])

  const handlePaymentBusiness = useCallback(() => {
    const checkoutForm = new FormData()
    checkoutForm.append("plan", "business")
    checkoutForm.append("billing_frequency", "monthly")
    checkoutForm.append(
      "success_redirect",
      "https://content.quillnow.com/payment/success"
    )
    checkoutForm.append(
      "cancel_redirect",
      "https://content.quillnow.com/payment/success"
    )
    checkoutForm.append("is_trial", true)
    dispatch(createCheckoutSession(checkoutForm))
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            Portfolio | QuillNow
          </title>
        </MetaTags>
        <Container fluid>
          <div className="row mb-0">
            <div className="tab-pane">
              <div className="row">
                <div className="col-sm-12">
                  <div className="justify-content-center row">
                    <div className="col-lg-6">
                      <div className="text-center mb-5">
                        <h4>
                          {user.subscription ? "Update" : "Pick up"} your
                          Subscription plan
                        </h4>
                        <p className="text-muted">
                          To achieve this, it would be necessary to have uniform
                          grammar, pronunciation and more common words If
                          several languages coalesce
                        </p>
                      </div>
                      <div className="slideToggle">
                        <label className="form-switch">
                          <span className="beforeinput">MONTHLY</span>
                          <input
                            className="bg-info"
                            type="checkbox"
                            id="js-contcheckbox"
                            onChange={e => handleChangePlan(e)}
                          />
                          <i></i>
                          <span className="afterinput">ANNUAL</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-center mb-4">
                    <>
                    <Subscription
                        name="Standard"
                        tagline={subscriptionPlan?.standard?.tag_line}
                        features={subscriptionPlan?.standard?.features}
                        prices={subscriptionPlan?.standard?.prices}
                        type={planSubscribe}
                        onClick={handlePaymentStandard}
                        icon={"walking"}
                      />
                      <Subscription
                        name="Essential"
                        tagline={subscriptionPlan?.essential?.tag_line}
                        features={subscriptionPlan?.essential?.features}
                        prices={subscriptionPlan?.essential?.prices}
                        type={planSubscribe}
                        onClick={handlePaymentEssential}
                        icon={"run"}
                      />
                      <Subscription
                        name="Business"
                        tagline={subscriptionPlan?.business?.tag_line}
                        features={subscriptionPlan?.business?.features}
                        prices={subscriptionPlan?.business?.prices}
                        type={planSubscribe}
                        onClick={handlePaymentBusiness}
                        icon={"cycling"}
                      />
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

UserSubscription.propTypes = {
  history: PropTypes.object,
}
export default withRouter(UserSubscription)
